<template>
  <v-container fluid>
    <h3 class="my-1">
      SERVICIOS A REALIZAR:<v-btn icon color="primary">
        <!-- v-if="tipo !== 'ver'" -->
        <!-- <v-icon>mdi-plus</v-icon> -->
      </v-btn>
    </h3>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">ORIGEN</h4>

        <v-checkbox
          dense
          v-for="service in services.filter((v) => v.id_begend == 1)"
          :key="service.id"
          v-model="service.status"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">FLETE</h4>

        <v-checkbox
          dense
          v-for="service in services.filter((v) => v.id_begend == 3)"
          :key="service.id"
          v-model="service.status"
          :readonly="type == 'ver'"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">DESTINO</h4>

        <v-checkbox
          dense
          v-for="service in services.filter((v) => v.id_begend == 2)"
          :key="service.id"
          v-model="service.status"
          :readonly="type == 'ver'"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-3" v-if="this.services">
        <h4 class="mb-2">OPCIONAL</h4>

        <v-checkbox
          dense
          v-for="service in services.filter((v) => v.id_begend == 4)"
          :key="service.id"
          v-model="service.status"
          :readonly="type == 'ver'"
        >
          <template v-slot:label>
            {{ service.namegroupservice }}
          </template>
        </v-checkbox>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "serviciosCasilleroComponet",
  props: ["type"],
  data() {
    return {
      begEndList: [],
    };
  },
  mounted() {},
  methods: {
    ...mapActions([]),
  },
  computed: {
    ...mapState(["services", "costos"]),
  },
};
</script>

<style></style>
